import * as polyfills from "./scripts/polyfills/ie";

import Collapse from 'bootstrap.native/dist/components/collapse-native.esm';
onFind('[data-toggle="collapse"]',function (element) {
    new Collapse(element);
});

import Tab from 'bootstrap.native/dist/components/tab-native.esm';
onFind('[data-toggle="tab"]',function (element) {
    new Tab(element);
});


import './scripts/common';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


/**********************************
 *
 *      Project Stuff
 *
 * ********************************/
import {onFind} from "@c0necto/elements-init-modules-in-scope";

import * as lightbox from './scripts/lightbox';
lightbox.init({
    thumbnail:false,
    closable:true
});

import * as imgTeaserSwiper from './scripts/img-teaser-swiper';
imgTeaserSwiper.init();

import * as accoTeaserSwiper from './scripts/acco-teaser-swiper';
accoTeaserSwiper.init();

import * as imageSwiper from './scripts/image-swiper';
imageSwiper.init();

import * as imgContentTeaserSwiper from './scripts/img-content-teaser-swiper';
imgContentTeaserSwiper.init();

import * as partnerSlideSwiper from './scripts/partner-slide-swiper';
partnerSlideSwiper.init();

import * as accoTeaserHoriSwiper from './scripts/acco-teaser-hori-swiper';
accoTeaserHoriSwiper.init();

import * as animationMoveUp from './scripts/animation-move-up';
animationMoveUp.init();

import * as datepicker from '@c0necto/elements-datepicker';
let datepickerOptions = {}
if (_config.lang !== 'de'){
    datepickerOptions = {
        locale: {
            firstDayOfWeek: 1
        }
    }
}
datepicker.init(datepickerOptions);

import * as datepickerRange from '@c0necto/elements-datepicker-range';
datepickerRange.init();

import * as ajaxForm from '@c0necto/elements-ajax-form';
ajaxForm.init();

import * as video from './scripts/video';
video.init();

import * as shariff from './scripts/shariff';
shariff.init();

import * as typeahead from './scripts/typeahead';
typeahead.init();

import * as ajaxFormScrollTop from './scripts/ajax-form-scroll-top';
ajaxFormScrollTop.init();