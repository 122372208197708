"use strict";

import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {on, find, trigger} from "@c0necto/elements-dom-utils";

export function init() {
    onFind('.js-ajax-form', function (element) {
        on('fetched.ajax-form', (evt) => successHandler( evt.detail, evt.target), element);

        const successHandler = (params, element) => {
           let scrollToTopAttr = element.getAttribute('data-ajax-form-scroll-to-top');

          if(scrollToTopAttr.includes('#') ){
              // scroll to specific id when the attribte 'data-ajax-form-scroll-top-top' is set with a id
              scrollToElement(find(scrollToTopAttr));
            }else if(scrollToTopAttr === 'true'){
              // scroll to beginning of ajax-form when the attribte 'data-ajax-form-scroll-top-top' is set
              scrollToElement(element);
          }
        };
    });
}

function scrollToElement(element){
    let bodyRect = document.body.getBoundingClientRect().top;
    let elementRect = element.getBoundingClientRect().top;
    let elementPosition = elementRect - bodyRect;
    let offsetPosition = elementPosition - 150;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}
